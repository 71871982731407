import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { pick } from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { RECAPTCHA_ACTIONS } from '@oup/shared-node-browser/constants';
import styles from './RvsForm.scss';
import constants from './constants';
import { RvsLayoutConstants } from '../../globals/rvsConstants';
import { getLanguage } from '../../utils/manageSelectorLanguage';

import withLocalizedContent from '../../language/withLocalizedContent';
import PersistentStorage from '../../utils/storage/Persistent';
import actions from '../../redux/actions';

import ControlledForm from '../ControlledForm/ControlledForm';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import TextInput from '../TextInput/TextInput';
import DateField from '../DateField/DateField';
import ScoreScaleModal from '../ScoreScaleModal/ScoreScaleModal';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import { Action } from '../ActionList/ActionList';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import ErrorStrip from '../ErrorStrip/ErrorStrip';
import { hasSurpassedInvalidLimit, resetInvalidLimit } from '../../utils/rvs/failedRequestsCounter';
import { getCurrentPlatform } from '../../utils/platform';

function RvsForm({
  data,
  disabled = false,
  readOnly = false,
  displayBodyOnly = false,
  customClassName,
  errors = {},
  submitting,
  success,
  failure,
  validate,
  reset,
  submit,
  localizedContent: { rvsFormPage: content, scoreScaleModal: modalContent },
  googleReCaptchaProps,
  bot,
  unavailableApi
}) {
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(data);
  const [testCenterInputsVisibility, setTestCenterInputsVisibility] = useState({
    speakingTestCenterID: false,
    listeningTestCenterID: false,
    readingTestCenterID: false,
    writingTestCenterID: false
  });

  useEffect(() => setFormData(data), [data]);

  const { CERTIFICATE_TYPE, ERROR_TYPE, SUBMIT_TIMEOUT } = constants;
  const advanced = formData.certificateType === CERTIFICATE_TYPE.ADVANCED ? content.advanced_prefix_label : '';

  const fieldErrors = Object.values(errors);
  const errorValues = fieldErrors.map(error => error.value);

  const isFieldDisabled = isLoading || disabled;

  const requiredFormData = Object.keys(formData)
    .filter(key => !(key in testCenterInputsVisibility && testCenterInputsVisibility[key] === false))
    .reduce((obj, key) => {
      obj[key] = formData[key];
      return obj;
    }, {});

  const isSubmitDisabled =
    !errorValues.length ||
    errorValues.some(Boolean) ||
    !Object.values(requiredFormData).every(Boolean) ||
    formData.certificateType === CERTIFICATE_TYPE.DEFAULT;
  const showHelpAndSupport = errorValues.some(Boolean);

  const changeCefrLevels = (certificateType, langCode) => {
    let cefrLevels = [];
    if (langCode === 'es') {
      if (certificateType === '2') {
        cefrLevels = [
          {
            value: 'Below B2',
            text: 'Por debajo de B2'
          },
          {
            value: 'B2',
            text: 'B2'
          },
          {
            value: 'C1',
            text: 'C1'
          }
        ];
      } else {
        cefrLevels = [
          {
            value: 'Below A2',
            text: 'Por debajo de A2'
          },
          {
            value: 'A2',
            text: 'A2'
          },
          {
            value: 'B1',
            text: 'B1'
          },
          {
            value: 'B2',
            text: 'B2'
          }
        ];
      }
    }
    if (langCode !== 'es') {
      if (certificateType === '2') {
        cefrLevels = [
          {
            value: 'Below B2',
            text: 'Below B2'
          },
          {
            value: 'B2',
            text: 'B2'
          },
          {
            value: 'C1',
            text: 'C1'
          }
        ];
      } else {
        cefrLevels = [
          {
            value: 'Below A2',
            text: 'Below A2'
          },
          {
            value: 'A2',
            text: 'A2'
          },
          {
            value: 'B1',
            text: 'B1'
          },
          {
            value: 'B2',
            text: 'B2'
          }
        ];
      }
    }

    return cefrLevels;
  };

  const langCode = getLanguage();

  formData.cefrLevels = changeCefrLevels(formData.certificateType, langCode);

  const onChangeHandler = name => value => {
    const newData = { [name]: value };

    if (name === 'certificateType') {
      newData.cefrLevels = changeCefrLevels(value);
    }

    setFormData({ ...formData, ...newData });
  };

  const toggleTestCenterVisibility = (name, moduleDate, date = '2025-01-06') => {
    if (!['speakingDateTaken', 'listeningDateTaken', 'readingDateTaken', 'writingDateTaken'].includes(name)) return;

    // Include for any certificate with a date of 7 Jan or later.
    const isAfter = moment(moduleDate).isAfter(date);

    const testCenterInput = {
      speakingDateTaken: 'speakingTestCenterID',
      listeningDateTaken: 'listeningTestCenterID',
      readingDateTaken: 'readingTestCenterID',
      writingDateTaken: 'writingTestCenterID'
    }[name];

    setTestCenterInputsVisibility(prev => ({
      ...prev,
      [testCenterInput]: isAfter
    }));

    // -- clear test center id value
    if (!isAfter) {
      setFormData(prev => ({
        ...prev,
        [testCenterInput]: undefined
      }));
    }
  };

  const onDateChangeHandler = name => date => {
    const formattedDate = moment(date).format('DD MMMM YYYY');
    setFormData({ ...formData, [name]: formattedDate });
    toggleTestCenterVisibility(name, formattedDate);
  };

  const onBlurHandler = name => () => {
    if (name === 'testTakerNumber') {
      return validate(pick({ ...formData }, [name, 'certificateReferenceNumber']));
    }
    if (name === 'certificateReferenceNumber') {
      return validate(pick({ ...formData }, [name, 'testTakerNumber']));
    }

    return validate(pick({ ...formData }, [name]));
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);

    const token = await googleReCaptchaProps.executeRecaptcha(RECAPTCHA_ACTIONS.UPLOAD_CERTIFICATE);
    formData.reCAPTCHAToken = token;
    formData.reCAPTCHATokenSource = getCurrentPlatform();
    submit(formData);

    setTimeout(() => {
      setIsLoading(false);
    }, SUBMIT_TIMEOUT);
  };
  const resetOnCancel = () => {
    const fieldsName = Object.keys(formData);
    fieldsName.forEach(field => {
      const errorField = errors[field];
      if (errorField && errorField?.value === true && errorField?.type !== null) {
        errorField.value = false;
        errorField.type = null;
      }
    });
  };
  const getValidationStatus = (fieldName, fieldLabel) => {
    const { type: errorType } = errors[fieldName];

    switch (errorType) {
      case ERROR_TYPE.EMPTY_FIELD:
        return { isActive: true, message: content.empty_field_error };
      case ERROR_TYPE.INVALID_FORMAT:
        return {
          isActive: true,
          message: `${content.invalid_format_error_first_part} ${fieldLabel.toLowerCase()} ${
            content.invalid_format_error_second_part
          }`
        };
      case ERROR_TYPE.INVALID_DATE_PERIOD:
        return {
          isActive: true,
          message: content.invalid_date_period_error,
          isHtml: true
        };
      case ERROR_TYPE.EMPTY_TEST_TAKER_NAME:
        return { isActive: true, message: content.empty_test_taker_name_error };
      case ERROR_TYPE.EMPTY_DOB:
        return { isActive: true, message: content.empty_dob_error };
      case ERROR_TYPE.EMPTY_TEST_TAKER_NUMBER:
        return { isActive: true, message: content.empty_test_taker_number_error };
      case ERROR_TYPE.EMPTY_CERTIFICATE_REFERENCE_NUMBER:
        return { isActive: true, message: content.empty_certificate_reference_number_error };
      case ERROR_TYPE.INVALID_CERTIFICATE_TYPE:
        return { isActive: true, message: content.invalid_certificate_type_error };
      case ERROR_TYPE.EMPTY_OVERALL_SCORE:
        return { isActive: true, message: content.empty_overall_score_error };
      case ERROR_TYPE.EMPTY_OVERALL_CEFR_LEVEL:
        return { isActive: true, message: content.empty_overall_cefr_level_error };
      case ERROR_TYPE.EMPTY_SPEAKING_SCORE:
        return { isActive: true, message: content.empty_speaking_score_error };
      case ERROR_TYPE.EMPTY_SPEAKING_CEFR_LEVEL:
        return { isActive: true, message: content.empty_speaking_cefr_level_error };
      case ERROR_TYPE.EMPTY_SPEAKING_DATE_TAKEN:
        return { isActive: true, message: content.empty_speaking_date_taken_error };
      case ERROR_TYPE.EMPTY_LISTENING_SCORE:
        return { isActive: true, message: content.empty_listening_score_error };
      case ERROR_TYPE.EMPTY_LISTENING_CEFR_LEVEL:
        return { isActive: true, message: content.empty_listening_cefr_level_error };
      case ERROR_TYPE.EMPTY_LISTENING_DATE_TAKEN:
        return { isActive: true, message: content.empty_listening_date_taken_error };
      case ERROR_TYPE.EMPTY_READING_SCORE:
        return { isActive: true, message: content.empty_reading_score_error };
      case ERROR_TYPE.EMPTY_READING_CEFR_LEVEL:
        return { isActive: true, message: content.empty_reading_cefr_level_error };
      case ERROR_TYPE.EMPTY_READING_DATE_TAKEN:
        return { isActive: true, message: content.empty_reading_date_taken_error };
      case ERROR_TYPE.EMPTY_WRITING_SCORE:
        return { isActive: true, message: content.empty_writing_score_error };
      case ERROR_TYPE.EMPTY_WRITING_CEFR_LEVEL:
        return { isActive: true, message: content.empty_writing_cefr_level_error };
      case ERROR_TYPE.EMPTY_WRITING_DATE_TAKEN:
        return { isActive: true, message: content.empty_writing_date_taken_error };
      case ERROR_TYPE.INVALID_CEFR_LEVEL:
        return { isActive: true, message: content.invalid_cefr_level_error };
      case ERROR_TYPE.INVALID_CRN:
        return {
          isActive: true,
          message: content.invalid_crn_error,
          isHtml: true
        };
      default:
        return { isActive: null, message: null };
    }
  };

  const history = useHistory();
  const navigate = url => {
    history.push(url);
  };

  if (!submitting) {
    if (bot) {
      const botStorage = new PersistentStorage('rvsErrorBot');
      botStorage.set(true);
      navigate(RvsLayoutConstants.PATH_NAMES.ERROR_PAGE);
    }

    if (unavailableApi) {
      navigate(RvsLayoutConstants.PATH_NAMES.ERROR_PAGE);
    }

    if (failure) {
      if (hasSurpassedInvalidLimit()) {
        navigate(RvsLayoutConstants.PATH_NAMES.SUPPORT_MESSAGE_PAGE);
      } else {
        const redirectLocationStorage = new PersistentStorage('redirectLocation');
        redirectLocationStorage.set('invalid');

        navigate(RvsLayoutConstants.PATH_NAMES.INVALID_CERTIFICATE_PAGE);
      }
      reset();
    } else if (success) {
      resetInvalidLimit();

      const redirectLocationStorage = new PersistentStorage('redirectLocation');
      redirectLocationStorage.set('valid');

      navigate(RvsLayoutConstants.PATH_NAMES.VALID_CERTIFICATE_PAGE);
    }
  }

  return (
    <div className={classnames(styles.formContainer, customClassName)}>
      <div className={styles.wrapper}>
        {!displayBodyOnly && (
          <>
            <h1>{content.page_title}</h1>
            <p className={styles.certificateInfo}>
              {content.top_text_line1}&nbsp;
              <span className={styles.topText}>{content.top_text_line2}</span>
              &nbsp;{content.top_text_line3}
            </p>
          </>
        )}
        <ControlledForm>
          <fieldset id="block-1" className={styles.fieldset}>
            <h2 className={styles.sectionTitle}>{content.test_taker_details_title}</h2>
            <div className={styles.field}>
              <div className={styles.input}>
                <ValidationStatus
                  forId="testTakerName"
                  {...getValidationStatus('testTakerName', content.test_taker_name_label)}
                >
                  <TextInput
                    id="testTakerName"
                    name="testTakerName"
                    type="text"
                    required
                    ariaLabel="Test taker details
                    testTakerName"
                    disabled={isFieldDisabled}
                    readOnly={readOnly}
                    label={content.test_taker_name_label}
                    placeholder={content.test_taker_name_placeholder}
                    value={formData.testTakerName}
                    onChange={onChangeHandler('testTakerName')}
                    onBlur={onBlurHandler('testTakerName')}
                    disableAutoComplete
                  />
                </ValidationStatus>
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.input}>
                <ValidationStatus
                  forId="dateOfBirth"
                  {...getValidationStatus('dateOfBirth', content.date_of_birth_label)}
                >
                  <DateField
                    id="dateOfBirth"
                    name="dateOfBirth"
                    customClassName={styles.dateFieldContainer}
                    disabled={isFieldDisabled}
                    readOnly={readOnly}
                    required
                    label={content.date_of_birth_label}
                    placeholder={content.date_of_birth_placeholder}
                    displayDateFormat="dd MMMM yyyy"
                    showMonthDropdown
                    showYearDropdown
                    value={formData.dateOfBirth}
                    showIcon
                    onChange={onDateChangeHandler('dateOfBirth')}
                    onBlur={onBlurHandler('dateOfBirth')}
                    autoComplete="off"
                  />
                </ValidationStatus>
              </div>
            </div>
          </fieldset>
          <fieldset id="block-2" className={styles.fieldset}>
            <h2 className={styles.sectionTitle}>{content.certificate_details_title}</h2>
            <div className={styles.field}>
              <div className={styles.input}>
                <ValidationStatus
                  forId="testTakerNumber"
                  {...getValidationStatus('testTakerNumber', content.test_taker_number_label)}
                >
                  <TextInput
                    id="testTakerNumber"
                    name="testTakerNumber"
                    type="text"
                    required
                    ariaLabel="Certificate details testTakerNumber"
                    disabled={isFieldDisabled}
                    readOnly={readOnly}
                    label={content.test_taker_number_label}
                    placeholder={content.test_taker_number_placeholder}
                    value={formData.testTakerNumber}
                    onChange={onChangeHandler('testTakerNumber')}
                    onBlur={onBlurHandler('testTakerNumber')}
                    disableAutoComplete
                  />
                </ValidationStatus>
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.input}>
                <ValidationStatus
                  forId="certificateReferenceNumber"
                  {...getValidationStatus('certificateReferenceNumber', content.certificate_reference_number_label)}
                >
                  <TextInput
                    id="certificateReferenceNumber"
                    name="certificateReferenceNumber"
                    type="text"
                    disabled={isFieldDisabled}
                    readOnly={readOnly}
                    required
                    label={content.certificate_reference_number_label}
                    placeholder={content.certificate_reference_number_placeholder}
                    value={formData.certificateReferenceNumber}
                    onChange={onChangeHandler('certificateReferenceNumber')}
                    onBlur={onBlurHandler('certificateReferenceNumber')}
                    disableAutoComplete
                  />
                </ValidationStatus>
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.input}>
                <ValidationStatus
                  forId="certificateType"
                  {...getValidationStatus('certificateType', content.certificate_type_label)}
                >
                  <Dropdown
                    id="certificateType"
                    name="certificateType"
                    required
                    disabled={isFieldDisabled}
                    readOnly={readOnly}
                    label={content.certificate_type_label}
                    options={[
                      {
                        value: '0',
                        text: content.certificate_type_placeholder
                      },
                      {
                        value: '1',
                        text: content.certificate_type_option_basic
                      },
                      {
                        value: '2',
                        text: content.certificate_type_option_advanced
                      }
                    ]}
                    value={formData.certificateType}
                    onChange={onChangeHandler('certificateType')}
                    onBlur={onBlurHandler('certificateType')}
                  />
                </ValidationStatus>
              </div>
            </div>
          </fieldset>

          <fieldset id="block-3" className={styles.fieldset}>
            <h2 className={classnames(styles.sectionTitle, styles.scoreTitle)}>{content.results_overview_title}</h2>
            <p>
              {content.score_overview_description_first_part}&nbsp;
              <a
                href={content.help_and_support_link_url}
                target="_blank"
                className={styles.helpAndSupport}
                rel="noreferrer"
              >
                {content.help_and_support_link_text}
              </a>
              &nbsp;{content.score_overview_description_second_part}
            </p>
            <div className={styles.field}>
              <div className={styles.input}>
                <ValidationStatus
                  forId="overallScore"
                  {...getValidationStatus('overallScore', content.overall_score_label)}
                >
                  <TextInput
                    id="overallScore"
                    name="overallScore"
                    type="text"
                    required
                    ariaLabel="Score overview overallScore"
                    disabled={isFieldDisabled}
                    readOnly={readOnly}
                    label={content.overall_score_label}
                    placeholder={content.overall_score_placeholder}
                    value={formData.overallScore}
                    onChange={onChangeHandler('overallScore')}
                    onBlur={onBlurHandler('overallScore')}
                    disableAutoComplete
                  />
                </ValidationStatus>
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.input}>
                <ValidationStatus
                  forId="overallCefrLevel"
                  {...getValidationStatus('overallCefrLevel', content.overall_cefr_level_label)}
                >
                  <Dropdown
                    id="overallCefrLevel"
                    name="overallCefrLevel"
                    required
                    disabled={isFieldDisabled}
                    readOnly={readOnly}
                    label={content.overall_cefr_level_label}
                    options={[
                      {
                        value: '',
                        text: content.cefr_level_placeholder
                      },
                      ...formData.cefrLevels
                    ]}
                    value={formData.overallCefrLevel}
                    onChange={onChangeHandler('overallCefrLevel')}
                    onBlur={onBlurHandler('overallCefrLevel')}
                  />
                </ValidationStatus>
              </div>
            </div>
          </fieldset>

          <fieldset id="block-4" className={classnames([styles.fieldset], [styles.scoreSection])}>
            <h2 className={styles.sectionTitle}>{content.module_results_title}</h2>
            <div className={styles.group}>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="speakingScore"
                    {...getValidationStatus('speakingScore', content.speaking_score_label)}
                  >
                    <TextInput
                      id="speakingScore"
                      name="speakingScore"
                      type="text"
                      ariaLabel="scores in detail speaking score"
                      required
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      label={`${advanced} ${content.speaking_score_label}`}
                      placeholder={content.speaking_score_placeholder}
                      value={formData.speakingScore}
                      onChange={onChangeHandler('speakingScore')}
                      onBlur={onBlurHandler('speakingScore')}
                      disableAutoComplete
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="speakingCefrLevel"
                    {...getValidationStatus('speakingCefrLevel', content.speaking_cefr_level_label)}
                  >
                    <Dropdown
                      id="speakingCefrLevel"
                      name="speakingCefrLevel"
                      required
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      label={`${advanced} ${content.speaking_cefr_level_label}`}
                      options={[
                        {
                          value: '',
                          text: content.cefr_level_placeholder
                        },
                        ...formData.cefrLevels
                      ]}
                      value={formData.speakingCefrLevel}
                      onChange={onChangeHandler('speakingCefrLevel')}
                      onBlur={onBlurHandler('speakingCefrLevel')}
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="speakingDateTaken"
                    {...getValidationStatus('speakingDateTaken', content.speaking_date_taken_label)}
                  >
                    <DateField
                      id="speakingDateTaken"
                      name="speakingDateTaken"
                      customClassName={styles.dateFieldContainer}
                      value={formData.speakingDateTaken}
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      required
                      label={content.speaking_date_taken_label}
                      placeholder={content.speaking_date_taken_placeholder}
                      displayDateFormat="dd MMMM yyyy"
                      selected={formData.speakingDateTaken}
                      showMonthDropdown
                      showYearDropdown
                      onChange={onDateChangeHandler('speakingDateTaken')}
                      onBlur={onBlurHandler('speakingDateTaken')}
                      autoComplete="off"
                    />
                  </ValidationStatus>
                </div>
              </div>
              {testCenterInputsVisibility.speakingTestCenterID && (
                <div className={styles.field}>
                  <div className={styles.input}>
                    <ValidationStatus
                      forId="speakingTestCenterID"
                      {...getValidationStatus('speakingTestCenterID', content.speaking_test_center_id_label)}
                    >
                      <TextInput
                        id="speakingTestCenterID"
                        name="speakingTestCenterID"
                        type="text"
                        ariaLabel={content.speaking_test_center_id_label}
                        required
                        disabled={isFieldDisabled}
                        readOnly={readOnly}
                        label={`${advanced} ${content.speaking_test_center_id_label}`}
                        placeholder={content.speaking_test_center_id_placeholder}
                        value={formData.speakingTestCenterID}
                        onChange={onChangeHandler('speakingTestCenterID')}
                        onBlur={onBlurHandler('speakingTestCenterID')}
                        disableAutoComplete
                      />
                    </ValidationStatus>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.group}>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="listeningScore"
                    {...getValidationStatus('listeningScore', content.listening_score_label)}
                  >
                    <TextInput
                      id="listeningScore"
                      name="listeningScore"
                      type="text"
                      required
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      label={`${advanced} ${content.listening_score_label}`}
                      placeholder={content.listening_score_placeholder}
                      value={formData.listeningScore}
                      onChange={onChangeHandler('listeningScore')}
                      onBlur={onBlurHandler('listeningScore')}
                      disableAutoComplete
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="listeningCefrLevel"
                    {...getValidationStatus('listeningCefrLevel', content.listening_cefr_level_label)}
                  >
                    <Dropdown
                      id="listeningCefrLevel"
                      name="listeningCefrLevel"
                      required
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      label={`${advanced} ${content.listening_cefr_level_label}`}
                      options={[
                        {
                          value: '',
                          text: content.cefr_level_placeholder
                        },
                        ...formData.cefrLevels
                      ]}
                      value={formData.listeningCefrLevel}
                      onChange={onChangeHandler('listeningCefrLevel')}
                      onBlur={onBlurHandler('listeningCefrLevel')}
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="listeningDateTaken"
                    {...getValidationStatus('listeningDateTaken', content.listening_date_taken_label)}
                  >
                    <DateField
                      id="listeningDateTaken"
                      name="listeningDateTaken"
                      customClassName={styles.dateFieldContainer}
                      value={formData.listeningDateTaken}
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      required
                      label={content.listening_date_taken_label}
                      placeholder={content.listening_date_taken_placeholder}
                      displayDateFormat="dd MMMM yyyy"
                      selected={formData.listeningDateTaken}
                      showMonthDropdown
                      showYearDropdown
                      onChange={onDateChangeHandler('listeningDateTaken')}
                      onBlur={onBlurHandler('listeningDateTaken')}
                      autoComplete="off"
                    />
                  </ValidationStatus>
                </div>
              </div>
              {testCenterInputsVisibility.listeningTestCenterID && (
                <div className={styles.field} data-dynamic>
                  <div className={styles.input}>
                    <ValidationStatus
                      forId="listeningTestCenterID"
                      {...getValidationStatus('listeningTestCenterID', content.listening_test_center_id_label)}
                    >
                      <TextInput
                        id="listeningTestCenterID"
                        name="listeningTestCenterID"
                        type="text"
                        ariaLabel={content.listening_test_center_id_label}
                        required
                        disabled={isFieldDisabled}
                        readOnly={readOnly}
                        label={`${advanced} ${content.listening_test_center_id_label}`}
                        placeholder={content.listening_test_center_id_placeholder}
                        value={formData.listeningTestCenterID}
                        onChange={onChangeHandler('listeningTestCenterID')}
                        onBlur={onBlurHandler('listeningTestCenterID')}
                        disableAutoComplete
                      />
                    </ValidationStatus>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.group}>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="readingScore"
                    {...getValidationStatus('readingScore', content.reading_score_label)}
                  >
                    <TextInput
                      id="readingScore"
                      name="readingScore"
                      type="text"
                      required
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      label={`${advanced} ${content.reading_score_label}`}
                      placeholder={content.reading_score_placeholder}
                      value={formData.readingScore}
                      onChange={onChangeHandler('readingScore')}
                      onBlur={onBlurHandler('readingScore')}
                      disableAutoComplete
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="readingCefrLevel"
                    {...getValidationStatus('readingCefrLevel', content.reading_cefr_level_label)}
                  >
                    <Dropdown
                      id="readingCefrLevel"
                      name="readingCefrLevel"
                      required
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      label={`${advanced} ${content.reading_cefr_level_label}`}
                      options={[
                        {
                          value: '',
                          text: content.cefr_level_placeholder
                        },
                        ...formData.cefrLevels
                      ]}
                      value={formData.readingCefrLevel}
                      onChange={onChangeHandler('readingCefrLevel')}
                      onBlur={onBlurHandler('readingCefrLevel')}
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="readingDateTaken"
                    {...getValidationStatus('readingDateTaken', content.reading_date_taken_label)}
                  >
                    <DateField
                      id="readingDateTaken"
                      name="readingDateTaken"
                      customClassName={styles.dateFieldContainer}
                      value={formData.readingDateTaken}
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      required
                      label={content.reading_date_taken_label}
                      placeholder={content.reading_date_taken_placeholder}
                      displayDateFormat="dd MMMM yyyy"
                      selected={formData.readingDateTaken}
                      showMonthDropdown
                      showYearDropdown
                      onChange={onDateChangeHandler('readingDateTaken')}
                      onBlur={onBlurHandler('readingDateTaken')}
                      autoComplete="off"
                    />
                  </ValidationStatus>
                </div>
              </div>
              {testCenterInputsVisibility.readingTestCenterID && (
                <div className={styles.field} data-dynamic>
                  <div className={styles.input}>
                    <ValidationStatus
                      forId="readingTestCenterID"
                      {...getValidationStatus('readingTestCenterID', content.reading_test_center_id_label)}
                    >
                      <TextInput
                        id="readingTestCenterID"
                        name="readingTestCenterID"
                        type="text"
                        ariaLabel={content.reading_test_center_id_label}
                        required
                        disabled={isFieldDisabled}
                        readOnly={readOnly}
                        label={`${advanced} ${content.reading_test_center_id_label}`}
                        placeholder={content.reading_test_center_id_placeholder}
                        value={formData.readingTestCenterID}
                        onChange={onChangeHandler('readingTestCenterID')}
                        onBlur={onBlurHandler('readingTestCenterID')}
                        disableAutoComplete
                      />
                    </ValidationStatus>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.group}>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="writingScore"
                    {...getValidationStatus('writingScore', content.writing_score_label)}
                  >
                    <TextInput
                      id="writingScore"
                      name="writingScore"
                      type="text"
                      required
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      label={`${advanced} ${content.writing_score_label}`}
                      placeholder={content.writing_score_placeholder}
                      value={formData.writingScore}
                      onChange={onChangeHandler('writingScore')}
                      onBlur={onBlurHandler('writingScore')}
                      disableAutoComplete
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="writingCefrLevel"
                    {...getValidationStatus('writingCefrLevel', content.writing_cefr_level_label)}
                  >
                    <Dropdown
                      id="writingCefrLevel"
                      name="writingCefrLevel"
                      required
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      label={`${advanced} ${content.writing_cefr_level_label}`}
                      options={[
                        {
                          value: '',
                          text: content.cefr_level_placeholder
                        },
                        ...formData.cefrLevels
                      ]}
                      value={formData.writingCefrLevel}
                      onChange={onChangeHandler('writingCefrLevel')}
                      onBlur={onBlurHandler('writingCefrLevel')}
                    />
                  </ValidationStatus>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.input}>
                  <ValidationStatus
                    forId="writingDateTaken"
                    {...getValidationStatus('writingDateTaken', content.writing_date_taken_label)}
                  >
                    <DateField
                      id="writingDateTaken"
                      name="writingDateTaken"
                      customClassName={styles.dateFieldContainer}
                      value={formData.writingDateTaken}
                      disabled={isFieldDisabled}
                      readOnly={readOnly}
                      required
                      label={content.writing_date_taken_label}
                      placeholder={content.writing_date_taken_placeholder}
                      displayDateFormat="dd MMMM yyyy"
                      selected={formData.writingDateTaken}
                      showMonthDropdown
                      showYearDropdown
                      onChange={onDateChangeHandler('writingDateTaken')}
                      onBlur={onBlurHandler('writingDateTaken')}
                      autoComplete="off"
                    />
                  </ValidationStatus>
                </div>
              </div>
              {testCenterInputsVisibility.writingTestCenterID && (
                <div className={styles.field} data-dynamic>
                  <div className={styles.input}>
                    <ValidationStatus
                      forId="writingTestCenterID"
                      {...getValidationStatus('writingTestCenterID', content.writing_test_center_id_label)}
                    >
                      <TextInput
                        id="writingTestCenterID"
                        name="writingTestCenterID"
                        type="text"
                        ariaLabel={content.writing_test_center_id_label}
                        required
                        disabled={isFieldDisabled}
                        readOnly={readOnly}
                        label={`${advanced} ${content.writing_test_center_id_label}`}
                        placeholder={content.writing_test_center_id_placeholder}
                        value={formData.writingTestCenterID}
                        onChange={onChangeHandler('writingTestCenterID')}
                        onBlur={onBlurHandler('writingTestCenterID')}
                        disableAutoComplete
                      />
                    </ValidationStatus>
                  </div>
                </div>
              )}
            </div>
          </fieldset>

          {!displayBodyOnly && (
            <>
              {submitting && !isLoading && (
                <ErrorStrip
                  errorCustomClass={styles.errorStrip}
                  glyph={GLYPHS.ICON_VALIDATION_CROSS_ONBOARDING}
                  message={content.submit_error}
                />
              )}
              {showHelpAndSupport && (
                <div className={styles.finalText}>
                  {content.final_text}&nbsp;
                  <a href={content.help_and_support_link_url} target="_blank" rel="noreferrer">
                    {content.help_and_support_link_text}
                  </a>
                </div>
              )}
              <div className={styles.actions}>
                <Action
                  label={content.cancel_button}
                  back
                  className={styles.cancelButton}
                  onClick={() => {
                    resetOnCancel();
                    navigate(RvsLayoutConstants.PATH_NAMES.HOME_PAGE);
                  }}
                />
                <Button
                  primary
                  text={!isLoading && content.check_certificate_button}
                  id="rvs-manual-submit"
                  loading={isLoading}
                  customClassName={styles.checkButton}
                  disabled={isSubmitDisabled || isLoading}
                  onClick={onSubmitHandler}
                />
              </div>
            </>
          )}
        </ControlledForm>
      </div>
      <ScoreScaleModal isOpen={openPopup} closeModal={() => setOpenPopup(false)} content={modalContent} />
    </div>
  );
}

RvsForm.propTypes = {
  data: PropTypes.shape({
    testTakerName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    testTakerNumber: PropTypes.string,
    certificateReferenceNumber: PropTypes.string,
    certificateType: PropTypes.string,
    overallScore: PropTypes.string,
    overallCefrLevel: PropTypes.string,
    cefrLevels: PropTypes.array,
    speakingScore: PropTypes.string,
    speakingCefrLevel: PropTypes.string,
    speakingDateTaken: PropTypes.string,
    speakingTestCenterID: PropTypes.string,
    listeningScore: PropTypes.string,
    listeningCefrLevel: PropTypes.string,
    listeningDateTaken: PropTypes.string,
    listeningTestCenterID: PropTypes.string,
    readingScore: PropTypes.string,
    readingCefrLevel: PropTypes.string,
    readingDateTaken: PropTypes.string,
    readingTestCenterID: PropTypes.string,
    writingScore: PropTypes.string,
    writingCefrLevel: PropTypes.string,
    writingDateTaken: PropTypes.string,
    writingTestCenterID: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  displayBodyOnly: PropTypes.bool,
  customClassName: PropTypes.string,
  errors: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired,
  bot: PropTypes.bool.isRequired,
  unavailableApi: PropTypes.bool.isRequired
};

export default compose(
  withLocalizedContent('rvsFormPage', 'scoreScaleModal'),
  connect(
    state => {
      const {
        rvs: {
          form: { errors, submitting, success, failure, bot, unavailableApi }
        }
      } = state;

      return { errors, submitting, success, failure, bot, unavailableApi };
    },
    dispatch => ({
      validate: (input, compareTo) => {
        dispatch(actions.rvsFormValidateInput(input, compareTo));
      },
      submit: input => {
        dispatch(actions.rvsFormSubmit(input));
      },
      reset: () => {
        dispatch(actions.rvsFormReset());
      }
    })
  )
)(withGoogleReCaptcha(RvsForm));
